import {
  TableCell, TableRow,
} from '@mui/material';
import styled from '@emotion/styled';

import { useAvailableBillingEntities } from 'account/hooks/useListAvailableBillingEntities';

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f0f0f0;
  }
  background-color: ${({ selected }) => (selected ? '#6fa9db !important' : 'inherit')};
  cursor: pointer;

  :hover {
    background-color: #6fa9db;
  }
`;

const CustomTableCell = styled(TableCell)`
  font-size: inherit;
  cursor: pointer;
`;

function View({
  isSelected,
  handleClick,
  account_name,
  billing_account_code,
  formattedAddress,
}) {
  return (
    <StyledTableRow
      onClick={handleClick}
      selected={isSelected}
    >
      <CustomTableCell>{account_name}</CustomTableCell>
      <CustomTableCell>{billing_account_code}</CustomTableCell>
      <CustomTableCell>{formattedAddress}</CustomTableCell>
    </StyledTableRow>
  );
}

function Model({ id, onClick, ...props }) {
  const { billingEntries } = useAvailableBillingEntities();

  const {
    data,
  } = billingEntries;

  const {
    account_name = '',
    billing_account_code = '',
    address = {},
  } = data[id];

  const {
    city = '',
    region = '',
    postalCode = '',
  } = address || {};

  const formattedAddress = `${city} ${region} ${postalCode}`;

  const handleClick = () => {
    onClick(id);
  };

  const hookProps = {
    account_name,
    billing_account_code,
    formattedAddress,
    handleClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
}

export default Model;
export { View };
