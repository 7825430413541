import axios from "axios";
import Config from "../../config";
const config = new Config();

const axiosClient = axios.create({
    baseURL: `${config.BASEURL}`,
    responseType: "json",
    headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    }
});

/**
 * Sends a POST request to the specified URL with the given payload.
 * @param {string} url The URL to which the request is sent
 * @param {object} payload The JSON payload to send
 * @returns {Promise} A promise that resolves with the response of the POST request
 */
async function axiosPostJson(url, payload) {
    // Retrieve the token from local storage
    const storageItem = localStorage.getItem("adminDetails");
    if (!storageItem) {
        console.error("No admin details found in local storage.");
        return;
    }    
    const { token } = JSON.parse(storageItem);

    return await axiosClient.post(url, payload, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json"
            }
        })
        .catch(console.error);
};

/**
 * Sends a GET request to the specified URL with query parameters.
 * @param {string} url The URL to which the request is sent
 * @param {object} queryArgs An object containing the key-value pairs for the query string
 * @returns {Promise} A promise that resolves with the response of the GET request
 */
async function axiosGetJson(url, queryArgs) {
    // Retrieve the token from local storage
    const storageItem = localStorage.getItem("adminDetails");
    if (!storageItem) {
        console.error("No admin details found in local storage.");
        return;
    }    
    const { token } = JSON.parse(storageItem);

    // Convert queryArgs object to query string
    const queryParams = new URLSearchParams(queryArgs).toString();

    return await axiosClient.get(`${url}?${queryParams}`, {
        headers: {
            Authorization: token,
        }
    })
    .catch(console.error);
}

export default axiosClient;

export {axiosPostJson, axiosGetJson}; 