import { useModalStore } from 'common/hooks/useModals';
import {
  Dialog, Stack, Button, Divider,
} from '@mui/material';
import { useCallback } from 'react';
import styled from '@emotion/styled';
import UpdateAccount from 'account/components/UpdateAccountModal/UpdateAccount';
import { MdOutlineClose } from 'react-icons/md';

const ModalDivider = styled(Divider)`
  backgroundColor: '#000';
`;

const CloseButton = styled(Button)`
  font-size: 20px;
  color: black;
`;

const ModalHeaderActionContainer = styled(Stack)`
  justify-content: space-between;
`;

const CreateAccountHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding: 12px;
`;
function View({
  isOpen, company_id, old_account_name, handleClose, billing_type, account_style, account_status,
  session_duration, billing_account_code,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
    >
      <ModalHeaderActionContainer spacing={2} direction="row">
        <CreateAccountHeaderTitle>
          Update Account
        </CreateAccountHeaderTitle>
        <CloseButton variant="text" onClick={handleClose}>
          <MdOutlineClose />
        </CloseButton>
      </ModalHeaderActionContainer>
      <ModalDivider component="div" />
      <UpdateAccount
        isOpen={isOpen}
        company_id={company_id}
        old_account_name={old_account_name}
        billed={billing_type}
        style={account_style}
        status={account_status}
        session_duration={session_duration}
        billing_account_code={billing_account_code}
      />
    </Dialog>
  );
}

function Model() {
  const {
    updateAccount,
    setUpdateAccountState,
  } = useModalStore();

  const {
    isOpen,
    company_id,
    old_account_name,
    billing_type,
    account_style,
    account_status,
    session_duration,
    billing_account_code,
  } = updateAccount;

  const handleClose = useCallback(() => {
    setUpdateAccountState(false);
  }, [setUpdateAccountState]);

  const hookProps = {
    isOpen,
    company_id,
    old_account_name,
    handleClose,
    billing_type,
    account_style,
    account_status,
    session_duration,
    billing_account_code,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
