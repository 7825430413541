/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import ApiError from 'errors/ApiError';
import { useMemo } from 'react';
import { useUserData } from 'user/hooks/useUserData';

// also used by asset existance
export const getAircraftExistance = async (token, queryKey) => {
  // Extract the tailnumber from the queryKey
  const [,, country_code, reg_n_number] = queryKey;

  const response = await fetch(`${process.env.REACT_APP_BASEURL}/checkAircraftExists?country_code=${country_code}&reg_n_number=${reg_n_number}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new ApiError(response, data);
  }

  return {
    response,
    responseData: data,
  };
};

export const getQueryKey = (country_code, reg_n_number) => ['aircraftGlobals', 'doesAircraftExist', country_code, reg_n_number];

export function useAircraftExistance(country_code, reg_n_number) {
  const { userData } = useUserData();
  const { token } = userData;
  const key = useMemo(() => getQueryKey(country_code, reg_n_number), [country_code, reg_n_number]);

  const query = useQuery({
    queryKey: key,
    queryFn: ({ queryKey }) => getAircraftExistance(token, queryKey),
    enabled: !!reg_n_number && !!country_code,
  });

  return {
    query,
    fullResponse: query?.data?.responseData || {},
    aircraftExistance: query?.data?.responseData?.data || {},
  };
}
