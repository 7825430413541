/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import ApiError from 'errors/ApiError';
import { useMemo } from 'react';
import { useUserData } from 'user/hooks/useUserData';

const getAircraftCountriesList = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_BASEURL}/listCountries`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new ApiError(response, responseData);
  }

  return { response, responseData };
};

export const getQueryKey = () => ['aircraftGlobals', 'countriesList'];

export function useAircraftCountriesList() {
  const { userData } = useUserData();

  const { token } = userData;
  const key = useMemo(() => getQueryKey(), []);

  const query = useQuery({
    queryKey: key,
    queryFn: ({ queryKey }) => getAircraftCountriesList(token, queryKey),
  });

  return {
    query,
    fullResponse: query?.data?.responseData || {},
    aircraftCountries: query?.data?.responseData?.data || [],
  };
}
