import { Dialog } from '@mui/material';
import { useCallback } from 'react';

import { useModalStore } from 'common/hooks/useModals';
import ModalShell from './ModalShell';

function View({ isOpen, handleClose }) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
    >
      <ModalShell />
    </Dialog>
  );
}

function Model() {
  const {
    transferDeactivatedAircraft,
    setTransferDeactivatedAircraftState,
  } = useModalStore();

  const { isOpen } = transferDeactivatedAircraft;

  const handleClose = useCallback(() => {
    setTransferDeactivatedAircraftState(false);
  }, [setTransferDeactivatedAircraftState]);

  const hookProps = {
    isOpen,
    handleClose,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
