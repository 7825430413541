/* eslint-disable camelcase */
import {
  TextField, FormControl,
} from '@mui/material';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useState, useCallback, useMemo, useEffect,
} from 'react';

import { useAllAccounts, useAllAccountsParamStore } from 'account/hooks/useAllAccounts';
import HiddenInput from 'common/components/HiddenInput';
import { useDebounce } from 'common/hooks/useDebounce';

const TransferDescription = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10xp;
    padding-bottom: 12px;
  `;

const StyledFormControl = styled(FormControl)`
  margin-top: 10px;
`;

function View({
  reg_n_number,
  originAccountName,
  selectedDestinationAccount,
  selectedDestinationAccountId,
  destinationAccountList,
  handleChangeDestinationAccount,
}) {
  return (
    <>
      <TransferDescription>
        Transfer a deactivated aircraft, and all of it&apos;s files, from one account to another.
      </TransferDescription>
      <Grid>
        <Grid item>
          <StyledFormControl fullWidth>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Aircraft:</Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                <TextField
                  name="reg_n_number"
                  fullWidth
                  size="small"
                  type="text"
                  value={reg_n_number}
                  disabled
                />
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl fullWidth>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Origin Account:</Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="originAccount"
                  fullWidth
                  size="small"
                  type="text"
                  value={originAccountName}
                  disabled
                />
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl fullWidth size="small">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Destination Account</Grid>
              <Grid item xs={12} sm={6}>
                <HiddenInput name="dest_company_id" value={selectedDestinationAccountId} />
                <Autocomplete
                  id="destinationAccount"
                  value={selectedDestinationAccount}
                  inputValue={selectedDestinationAccount}
                  options={destinationAccountList}
                  filterOptions={(x) => x}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Account" name="destinationAccountName" size="small" onChange={handleChangeDestinationAccount} required />
                  )}
                  onChange={handleChangeDestinationAccount}
                />
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
      </Grid>
    </>
  );
}

function Model({ reg_n_number, originAccountName }) {
  const [currentAccountSearch, setCurrentAccountSearch] = useState('');
  const [selectedDestinationAccount, setSelectedDestinationAccount] = useState('');
  const [selectedDestinationAccountId, setSelectedDestinationAccountId] = useState('');

  const { allAccounts, totalAccountsCount, isFetching: isAccountSearchPending } = useAllAccounts();
  const { setSearchText } = useAllAccountsParamStore();
  const debouncedSearch = useDebounce(currentAccountSearch, 500);

  const destinationAccountList = useMemo(() => {
    if (isAccountSearchPending || currentAccountSearch !== debouncedSearch) {
      return ['Searching...'];
    }
    // grab account_names out of allAccounts, and if totalAccountsCount > 100, add a message that implies they need to type more to filter
    const accountList = allAccounts.map((account) => account.account_name);

    if (totalAccountsCount > 100) {
      accountList.push('Too many results to show, please narrow your search...');
    }

    return accountList;
  }, [allAccounts, currentAccountSearch, debouncedSearch, isAccountSearchPending, totalAccountsCount]);

  const handleChangeDestinationAccount = useCallback((e, newValue) => {
    if (newValue === null) {
      setSelectedDestinationAccount('');
      setCurrentAccountSearch('');
      setSelectedDestinationAccountId(null);
      return;
    }

    const selectedAccount = allAccounts.find((account) => account.account_name === e.target.innerText || account.account_name === e.target.value);

    if (selectedAccount) {
      setSelectedDestinationAccountId(selectedAccount.company_id);
    } else {
      setSelectedDestinationAccountId(null);
    }
    setCurrentAccountSearch(e.target.innerText || e.target.value);
    setSelectedDestinationAccount(e.target.innerText || e.target.value);
  }, [allAccounts]);

  useEffect(() => {
    setSearchText(debouncedSearch);
  }, [debouncedSearch, setSearchText]);

  const hookProps = {
    reg_n_number,
    originAccountName,
    selectedDestinationAccount,
    selectedDestinationAccountId,
    destinationAccountList,
    handleChangeDestinationAccount,
  };
  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
