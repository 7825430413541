const userReducer = (state = [], action) => {
     switch (action.type) {
         case 'ADD_DOCUMENT':
             return action;
        case 'GET_DOCUMENT':
            return action.data;
         default:
             return state;
     }
 }
 export default userReducer;
 
