import { useMutation } from '@tanstack/react-query';
import { useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useUserData } from 'user/hooks/useUserData';
import { useModalStore } from 'common/hooks/useModals';
import ApiError from 'errors/ApiError';
import { invalidateAccountDetails } from 'account/hooks/useAccountById';
import { invalidateAllAccounts } from 'account/hooks/useAllAccounts';
import { invalidateAccountAircraft } from './useAccountAircraft';

export const addAircraftToAccount = async ({
  token, company_id, country_code, reg_n_number, serial_number, make, model,
}) => fetch(`${process.env.REACT_APP_BASEURL}/admin/addAircraftToAccount`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
  body: JSON.stringify({
    country_code,
    reg_n_number,
    serial_number,
    make,
    model,
    company_id,
  }),
}).then(async (res) => {
  const data = await res.json();

  if (!res.ok) {
    throw new ApiError(res, data);
  }

  return {
    response: res,
    responseData: data,
  };
});

const useAddAircraftToAccount = () => {
  const {
    setAddAircraftState,
  } = useModalStore();

  const {
    userData,
  } = useUserData();

  const {
    token,
  } = userData;

  const mutation = useMutation({
    mutationFn: addAircraftToAccount,
    // eslint-disable-next-line no-unused-vars
    onError: (error, variables, context) => {
      if (error instanceof ApiError) {
        const { responseData } = error;

        toast.error(responseData.message);
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSuccess: async (data, variables, context) => {
      const { company_id } = variables;

      invalidateAllAccounts();
      invalidateAccountDetails({ company_id });
      invalidateAccountAircraft({ company_id });

      toast.success(data.responseData.message);
      setAddAircraftState(false);
    },
  });

  const doMutation = useCallback((params = {}, options = {}) => mutation.mutate({
    token, ...params,
  }, options), [mutation, token]);

  return useMemo(() => ({
    mutation: doMutation,
  }), [doMutation]);
};

export { useAddAircraftToAccount };
