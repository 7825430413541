import { useState } from 'react';
import {
  Button, DialogActions, DialogContent, Divider, FormControl, TextField, FormControlLabel,
} from '@mui/material';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import NumberInput from 'common/components/NumberInput';
import { useUpdateAccount } from 'account/hooks/useUpdateAccount';
import { serializeFormData } from 'common/util/serializeFormData';

const ModalDivider = styled(Divider)`
  backgroundColor: '#000';
`;

const ActionButton = styled(Button)`
  && {
    font-size: 10px;
    border: 2px solid #000;
    color: #fff;
    background-color: #1976d2;
    border-radius: 0;
  }
`;

const ModalFooterActionContainer = styled(DialogActions)`
  justify-content: space-between;
`;

const CreateAccountTextFieldTitle = styled.div`
  font-weight: bold;
`;

const GridContainer = styled(Grid)`
  margin-bottom: 16px;
`;

function View({
  onSubmit, session_duration, account_name,
  handleAccountChange, billed, style, status,
  billing_account_code,
}) {
  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Grid>
          <GridContainer item>
            <FormControl fullWidth>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                  <CreateAccountTextFieldTitle> Account Name: </CreateAccountTextFieldTitle>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="account_name"
                    value={account_name}
                    fullWidth
                    multiline
                    size="small"
                    type="text"
                    required
                    onChange={handleAccountChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CreateAccountTextFieldTitle>
                    This will only be changed if it is different from the current account name and not blank.
                  </CreateAccountTextFieldTitle>
                </Grid>
              </Grid>
            </FormControl>
          </GridContainer>
          <GridContainer item>
            <FormControl fullWidth>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                  <CreateAccountTextFieldTitle> Billing Type: </CreateAccountTextFieldTitle>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <RadioGroup
                    row
                    name="billing_type"
                    value={billed}
                  >
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <FormControlLabel value={true} control={<Radio />} label="Billed" disabled />
                    <FormControlLabel value={false} control={<Radio />} label="Unbilled" disabled />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CreateAccountTextFieldTitle>
                    Billing type cannot be changed
                  </CreateAccountTextFieldTitle>
                </Grid>
              </Grid>
            </FormControl>
          </GridContainer>
          <GridContainer item>
            <FormControl fullWidth>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                  <CreateAccountTextFieldTitle> Account Style: </CreateAccountTextFieldTitle>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <RadioGroup
                    row
                    name="style"
                    value={style}
                  >
                    <FormControlLabel value="traditional" control={<Radio />} label="Traditional" disabled />
                    <FormControlLabel value="parts" control={<Radio />} label="Parts" disabled />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CreateAccountTextFieldTitle>
                    Please open a support ticket to change Account Style. This has significant impact.
                  </CreateAccountTextFieldTitle>
                </Grid>
              </Grid>
            </FormControl>
          </GridContainer>
          <GridContainer item>
            <FormControl fullWidth>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                  <CreateAccountTextFieldTitle> Billing Code: </CreateAccountTextFieldTitle>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="billing_account_code"
                    fullWidth
                    multiline
                    size="small"
                    type="text"
                    defaultValue={billing_account_code}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CreateAccountTextFieldTitle>
                    Changing this has an immediate impact on the account. This should precisely match the account code in the billing system.
                  </CreateAccountTextFieldTitle>
                </Grid>
              </Grid>
            </FormControl>
          </GridContainer>
          <GridContainer item>
            <FormControl fullWidth>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                  <CreateAccountTextFieldTitle> Account status: </CreateAccountTextFieldTitle>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="status"
                    value={status}
                    fullWidth
                    multiline
                    size="small"
                    type="text"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CreateAccountTextFieldTitle>
                    This is controlled automatically by the billing system integration.
                  </CreateAccountTextFieldTitle>
                </Grid>
              </Grid>
            </FormControl>
          </GridContainer>
          <GridContainer item>
            <FormControl fullWidth>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                  <CreateAccountTextFieldTitle> Session duration: </CreateAccountTextFieldTitle>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumberInput
                    name="session_duration"
                    defaultValue={session_duration}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CreateAccountTextFieldTitle>
                    (Unit: minutes) Specifies the maximum length of a user session, from sign-in, after which they will need to sign-in again.
                  </CreateAccountTextFieldTitle>
                </Grid>
              </Grid>
            </FormControl>
          </GridContainer>
        </Grid>
      </DialogContent>
      <ModalDivider component="div" />
      <ModalFooterActionContainer>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={2} />
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={6}>
            <ActionButton variant="contained" type="submit">
              Save Changes
            </ActionButton>
          </Grid>
        </Grid>
      </ModalFooterActionContainer>
    </form>
  );
}

function Model({
  isOpen, company_id, old_account_name, billed, style, status, session_duration, billing_account_code,
}) {
  const [accountName, setAccountName] = useState(old_account_name);

  const { mutation: updateAccountMutation } = useUpdateAccount();

  // Use this to offset the save button for BT-3967
  const isModal = !!isOpen;

  const onSubmit = async (e) => {
    e.preventDefault();

    let newAccountName = null;
    if (accountName && old_account_name !== accountName) {
      newAccountName = accountName;
    }
    // Your input names should match these values where appropriate.
    const {
      billing_account_code: billingAccountCodeFromForm,
      session_duration: sessionDuration,
    } = serializeFormData(e);

    // Update Billing Code & Session Duration w/ API
    updateAccountMutation({
      company_id,
      billing_account_code: billingAccountCodeFromForm,
      session_duration: sessionDuration,
      newAccountName,
    });
  };

  const handleAccountChange = (e) => {
    setAccountName(e.target.value);
  };

  const hookProps = {
    isModal,
    onSubmit,
    session_duration,
    account_name: accountName,
    handleAccountChange,
    billed,
    style,
    status,
    billing_account_code,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
