import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton,
} from '@mui/material';
import styled from '@emotion/styled';
import { useState } from 'react';

import { useAvailableBillingEntities } from 'account/hooks/useListAvailableBillingEntities';
import HiddenInput from 'common/components/HiddenInput';
import RecurlyAccountRow from './Row';

const StyledTableContainer = styled(TableContainer)`
  max-height: 300px;
  overflow-y: auto;
`;

const TableCellHeader = styled(TableCell)`
  background-color: #fcfcfc;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  font-size: inherit;
  font-weight: bold;
`;

const CustomTable = styled(Table)({
  fontSize: '12px',
});

const NoDataDiv = styled.div`
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

function View({
  tableRows,
  selectedRow,
  handleRowClick,
  isLoading,
}) {
  return (
    <StyledTableContainer component={Paper}>
      <CustomTable stickyHeader>
        <TableHead>
          <TableRow>
            <TableCellHeader>Account Name</TableCellHeader>
            <TableCellHeader>Account Code</TableCellHeader>
            <TableCellHeader>Location</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && Array.from({ length: 5 }).map((num) => (
            <TableRow key={num}>
              <TableCell colSpan={3}>
                <Skeleton variant="rectangular" height={40} />
              </TableCell>
            </TableRow>
          ))}
          {!isLoading && tableRows.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <NoDataDiv>No unmatched accounts found in Recurly.</NoDataDiv>
                <NoDataDiv>Create an account in Recurly first.</NoDataDiv>
              </TableCell>
            </TableRow>
          )}
          {!isLoading && tableRows.length > 0 && tableRows.map((id) => (
            <RecurlyAccountRow
              key={id}
              id={id}
              onClick={() => handleRowClick(id)}
              isSelected={selectedRow === id}
            />
          ))}
        </TableBody>
      </CustomTable>
      {selectedRow && (
        <HiddenInput name="billing_account_code" value={selectedRow} />
      )}
    </StyledTableContainer>
  );
}

function Model() {
  const { billingEntries, isLoading } = useAvailableBillingEntities();
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    ids,
  } = billingEntries;

  const handleRowClick = (id) => {
    setSelectedRow(id);
  };

  const hookProps = {
    tableRows: ids,
    isLoading,
    selectedRow,
    handleRowClick,
  };
  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
