import { useMutation } from '@tanstack/react-query';
import { useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useUserData } from 'user/hooks/useUserData';
import { useModalStore } from 'common/hooks/useModals';
import ApiError from 'errors/ApiError';

export const initiateAircraftTransferBgJob = async ({
  token, reg_n_number, origin_company_id, dest_company_id, new_user_id,
}) => fetch(`${process.env.REACT_APP_BASEURL}/admin/initiateAircraftTransferBgJob`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
  body: JSON.stringify({
    reg_n_number,
    origin_company_id,
    dest_company_id,
    new_user_id,
  }),
}).then(async (res) => {
  const data = await res.json();

  if (!res.ok) {
    throw new ApiError(res, data);
  }

  return {
    response: res,
    responseData: data,
  };
});

const useTransferDeactivatedAircraft = () => {
  const {
    setTransferDeactivatedAircraftState,
  } = useModalStore();

  const {
    userData,
  } = useUserData();

  const {
    token,
  } = userData;

  const mutation = useMutation({
    mutationFn: initiateAircraftTransferBgJob,
    // eslint-disable-next-line no-unused-vars
    onError: (error, variables, context) => {
      if (error instanceof ApiError) {
        const { responseData } = error;

        toast.error(responseData.message);
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSuccess: async (data, variables, context) => {
      // TODO: Invalidate Deactivated Aircraft list

      toast.success(data.responseData.message);
      setTransferDeactivatedAircraftState(false);
    },
  });

  const doMutation = useCallback((params = {}, options = {}) => mutation.mutate({
    token, ...params,
  }, options), [mutation, token]);

  return useMemo(() => ({
    mutation: doMutation,
  }), [doMutation]);
};

export { useTransferDeactivatedAircraft };
