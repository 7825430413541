const subscriptionTabReducer = (state = [], action) => {
     switch (action.type) {
         case 'SUBSCRIPTIONS_TAB_TYPES':
            return action.data;
         default:
             return state;
     }
 }
 export default subscriptionTabReducer;
 
