const planeReducer = (state = [], action) => {
     switch (action.type) {
         case 'ADD_PLANE':
             return state.concat(action.data);
         case 'DELETE_PLANE':
            //return state.filter((post)=> post !== action.data);
		  return state.filter((post)=> post.payment_id !== action.data.payment_id);
        case 'GET_PLANE':
            return  action.data;
         default:
             return state;
     }
 }
 export default planeReducer;
 
