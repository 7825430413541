import React from 'react';
import { css, Global } from '@emotion/react';

const style = css({

});

function StyleRoot() {
  return (
    <Global
      styles={style}
    />
  );
}

export default StyleRoot;
