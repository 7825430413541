const totalDocuments = (state = [], action) => {
     switch (action.type) {
         case 'TOTAL_DOCUMENTS':
             return action;
         default:
             return state;
     }
 }
 export default totalDocuments;
 
