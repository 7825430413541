/* eslint-disable camelcase */
import {
  TextField, FormControl, Select, MenuItem, FormHelperText,
} from '@mui/material';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

import { useAircraftCountriesList } from 'aircraft/hooks/useGetAircraftCountries';
import { useAircraftMake } from 'aircraft/hooks/useListAircraftMakes';
import { useAircraftExistance } from 'aircraft/hooks/useCheckAircraftExists';
import { useAircraftMakeModel } from 'aircraft/hooks/useListAircraftMakeModel';
import { useState, useEffect } from 'react';
import { useDebounce } from 'common/hooks/useDebounce';
import HiddenInput from 'common/components/HiddenInput';
import { ADD_TO_ACCOUNT_STATUS, useCanAddToAccount } from 'aircraft/providers/CanAddToAccount';

const FoundAccountsHeader = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10xp;
    padding-bottom: 12px;
  `;

const AccountTypeHeader = styled.div`
    font-size: 16px;
    padding-bottom: 24px;
    padding-top: 24px;
  `;

const StyledFormControl = styled(FormControl)`
  margin-top: 10px;
`;

const AircraftCountryCode = styled.span`
  width: 40px;
  padding-top: 5px;
  margin-right: 5px;
`;

function View({
  countryList,
  aircraftMakes,
  tail,
  handleTailNumber,
  handleChangeCountry,
  country_code,
  handleChangeMake,
  handleChangeModel,
  handleChangeSerialNumber,
  aircraftModels,
  serial,
  selectedModel,
  selectedMake,
  error,
}) {
  return (
    <>
      <FoundAccountsHeader>
        Add an aircraft to the Bluetail account:
      </FoundAccountsHeader>
      <Grid>
        <Grid item>
          <StyledFormControl fullWidth size="small">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Country</Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  id="country"
                  name="country"
                  fullWidth
                  onChange={handleChangeCountry}
                  required
                  defaultValue={1}
                >
                  {countryList.map((item) => (
                    <MenuItem
                      key={item.country_id}
                      id={item.country_id}
                      value={item.country_id}
                    >
                      {item.country}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl fullWidth>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Tailnumber:</Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={1} sm={1}>
                    <AircraftCountryCode>
                      {country_code}
                    </AircraftCountryCode>
                    <HiddenInput name="country_code" value={country_code} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <TextField
                      name="reg_n_number"
                      fullWidth
                      size="small"
                      type="text"
                      value={tail}
                      onChange={handleTailNumber}
                      error={!!error}
                      required
                    />
                  </Grid>
                </Grid>
                {error && <FormHelperText error>{error}</FormHelperText>}
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl fullWidth>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Serial number</Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="serial_number"
                  fullWidth
                  size="small"
                  type="text"
                  required
                  value={serial}
                  onChange={handleChangeSerialNumber}
                />
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl fullWidth size="small">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Aircraft Make:</Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="make"
                  value={selectedMake}
                  inputValue={selectedMake}
                  options={aircraftMakes}
                  filterOptions={(options, { inputValue }) => {
                    // Only filter options if the input value has 3 or more characters
                    if (inputValue?.length < 3) {
                      return ['']; // Return an empty array to show no options
                    }
                    // Perform filtering or return all options if necessary
                    return options.filter((option) => option.make.toLowerCase().includes(inputValue.toLowerCase()));
                  }}
                  getOptionLabel={(option) => option.make || 'Enter at least 3 characters to search...'}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Make" name="make" size="small" onChange={handleChangeMake} required />
                  )}
                  onChange={handleChangeMake}
                />
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl fullWidth size="small">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>Aircraft Model</Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="model"
                  value={selectedModel}
                  inputValue={selectedModel}
                  options={aircraftModels}
                  getOptionLabel={(option) => `${option.model} (Make Model: ${option.aircraft_make_model})`}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Model" name="model" size="small" onChange={handleChangeModel} required />
                  )}
                  onChange={handleChangeModel}
                />
              </Grid>
            </Grid>
          </StyledFormControl>
        </Grid>
      </Grid>
      <AccountTypeHeader>
        Note: This aircraft is only visible to super admins until it has a subscription setup in Recurly
      </AccountTypeHeader>
    </>
  );
}

function Model({ onValidationChange }) {
  const [tail, setTail] = useState('');
  const [error, setError] = useState('');
  const [country_code, setCountry] = useState('N');
  const [aircraftSerial, setAircraftSerial] = useState('');
  const [selectedAircraftMake, setSelectedAircraftMake] = useState('');
  const [selectedAircraftModel, setSelectedAircraftModel] = useState('');
  const { setCanAddToAccount } = useCanAddToAccount();

  const { aircraftCountries } = useAircraftCountriesList();
  const { aircraftMakes } = useAircraftMake();
  const debouncedTail = useDebounce(tail, 750);
  const debouncedAircraftMake = useDebounce(selectedAircraftMake, 750);

  const { aircraftExistance } = useAircraftExistance(country_code, debouncedTail);
  const aircraftMakeModels = useAircraftMakeModel(debouncedAircraftMake);

  const {
    serial_number: existanceSerial,
    make: existanceMake,
    model: existanceModel,
    already_registered,
    exists_deactive,
  } = aircraftExistance;

  const handleChangeCountry = (e) => {
    const selectedCountry = aircraftCountries.find((item) => item.country_id === e.target.value);
    setCountry(selectedCountry.prefix);
  };

  const handleTailNumber = (e) => {
    setTail(e.target.value?.toUpperCase());
    // Regular expression to match only alphanumeric characters
    const regex = /^[A-Za-z0-9]*$/;

    if (regex.test(e.target.value)) {
      setError('');
      onValidationChange('');
    } else {
      setError('Input must contain only letters and numbers.');
      onValidationChange('error');
    }
  };

  const handleChangeSerialNumber = (e) => {
    setAircraftSerial(e.target.value);
  };

  const handleChangeModel = (e, newValue) => {
    if (newValue === null) {
      setSelectedAircraftModel('');
      return;
    }

    const splitModel = e.target.innerText ? e.target.innerText.split(' (Make Model: ') : e.target.value.split(' (Make Model: ');
    setSelectedAircraftModel(splitModel[0]);
  };

  const handleChangeMake = (e, newValue) => {
    if (newValue === null) {
      setSelectedAircraftMake('');
      return;
    }

    setSelectedAircraftMake(e.target.innerText || e.target.value);
  };

  useEffect(() => {
    if (existanceSerial) {
      setAircraftSerial(existanceSerial);
    }
    if (existanceMake) {
      setSelectedAircraftMake(existanceMake);
    }
    if (existanceModel) {
      setSelectedAircraftModel(existanceModel);
    }
  }, [existanceMake, existanceModel, existanceSerial]);

  useEffect(() => {
    if (already_registered) {
      setCanAddToAccount(ADD_TO_ACCOUNT_STATUS.ALREADY_REGISTERED);
    } else if (exists_deactive) {
      setCanAddToAccount(ADD_TO_ACCOUNT_STATUS.EXISTS_DEACTIVE);
    } else if (tail === '' || country_code === '' || aircraftSerial === '' || selectedAircraftMake === '' || selectedAircraftModel === '') {
      setCanAddToAccount(ADD_TO_ACCOUNT_STATUS.INCOMPLETE);
    } else {
      setCanAddToAccount(ADD_TO_ACCOUNT_STATUS.ACCEPTABLE);
    }
  }, [aircraftSerial, already_registered, country_code, selectedAircraftMake, selectedAircraftModel, setCanAddToAccount, tail]);

  const hookProps = {
    countryList: aircraftCountries || [],
    aircraftMakes,
    tail,
    handleTailNumber,
    handleChangeCountry,
    handleChangeSerialNumber,
    country_code,
    handleChangeMake,
    handleChangeModel,
    aircraftModels: aircraftMakeModels,
    serial: aircraftSerial,
    selectedModel: selectedAircraftModel,
    selectedMake: selectedAircraftMake,
    error,
  };
  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
