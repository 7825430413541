const categoryReducer = (state = [], action) => {
    let arr1 = []
    let arr2 = []
     switch (action.type) {
         case 'ADD_CATEGORY':
                action.data = [action.data].concat(state);
            
                if( action.data.length > 0)
                {
                    action.data.forEach(function(data,i){
                                if(data.user_id == 0)
                                {
                                    arr1.push(data)
                                }
                            })
                            action.data.forEach(function(data,i){
                                if(data.user_id != 0)
                                {
                                    arr2.push(data)
                                }
                            })
                }
                return   arr1.concat(arr2)
         case 'DELETE_CATEGORY':
            return state.filter((post)=> post.category_id !== action.data.category_id);
        case 'GET_CATEGORIES':
            //   let data=  action.data.sort.reverse()
            // var rev=action.data.reverse();  
            // return rev.sort(function (vote1, vote2) {
            //     if (vote1.user_id > 0) return 1;
            //     if (vote1.user_id == 0) return -1;
            //    });
            if( action.data.length > 0)
            {
                action.data.forEach(function(data,i){
                            if(data.user_id == 0)
                            {
                                arr1.push(data)
                            }
                        })
                        action.data.forEach(function(data,i){
                            if(data.user_id != 0)
                            {
                                arr2.push(data)
                            }
                        })
            }
            return   arr1.concat(arr2)
         default:
             return state;
     }
 }
 export default categoryReducer;
 
