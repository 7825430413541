import { create } from 'zustand';

const modals = [
  'createAccount',
  'addAircraft',
  'updateAccount',
  'transferDeactivatedAircraft',
];

const useModalStore = create((set, get) => ({
  // Iterate over modals to make a state for each
  ...modals.reduce((acc, modal) => {
    acc[modal] = {
      isOpen: false,
    };
    return acc;
  }, {}),

  // Build setter functions for each modal
  ...modals.reduce((acc, modal) => {
    acc[`set${modal[0].toUpperCase()}${modal.slice(1)}State`] = (isOpen, args = {}) => {
      // clear everything
      if (isOpen === false) {
        return set({
          [modal]: {
            ...args,
          },
        });
      }

      const currentState = get()[modal] || {};

      return set({
        [modal]: {
          ...currentState,
          ...args,
          isOpen: true,
        },
      });
    };
    return acc;
  }, {}),

  // Build reset function
  reset: () => {
    set({
      ...modals.reduce((acc, modal) => {
        acc[modal] = {
          isOpen: false,
        };
        return acc;
      }, {}),
    });
  },
}));

export { useModalStore };
