// A Provider to allow the components in AddAircraftModal to determine if the user can add an aircraft or asset to their account.

import {
  createContext, useContext, useMemo, useState,
} from 'react';

export const ADD_TO_ACCOUNT_STATUS = {
  ACCEPTABLE: 'ACCEPTABLE',
  ALREADY_REGISTERED: 'ALREADY_REGISTERED',
  EXISTS_DEACTIVE: 'EXISTS_DEACTIVE',
  INCOMPLETE: 'INCOMPLETE',
};

const CanAddToAccountContext = createContext();

export function CanAddToAccountProvider({ children }) {
  const [canAddToAccount, setCanAddToAccount] = useState(ADD_TO_ACCOUNT_STATUS.INCOMPLETE);
  const providedValue = useMemo(() => ({ canAddToAccount, setCanAddToAccount }), [canAddToAccount]);

  return (
    <CanAddToAccountContext.Provider value={providedValue}>
      {children}
    </CanAddToAccountContext.Provider>
  );
}

export const useCanAddToAccount = () => useContext(CanAddToAccountContext);
