const defaultPlane = (state = JSON.parse(localStorage.getItem('userDetails')) ?JSON.parse(localStorage.getItem('userDetails')).planeNumbers[0] : '', action) => {
	
     switch (action.type) {
         case 'ADD_DEFAULT_PLANE':
             return action.data
         default:
             return state;
     }
     
 }
 export default defaultPlane;
 
