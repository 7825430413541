/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import ApiError from 'errors/ApiError';
import { useMemo } from 'react';
import { useUserData } from 'user/hooks/useUserData';

const fetchBillingEntities = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/listAvailableBillingEntities`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const result = await response.json();

  const billingData = result.data.reduce((acc, item) => {
    acc.data[item.billing_account_code] = item;
    acc.ids.push(item.billing_account_code);
    return acc;
  }, { ids: [], data: {} });

  if (!response.ok) {
    throw new ApiError(response, billingData);
  }

  return { response, data: billingData };
};

export const getQueryKey = (admin_id) => [admin_id, 'availableAccounts'];

export function useAvailableBillingEntities() {
  const {
    userData,
  } = useUserData();

  const {
    token,
    admin_id,
  } = userData;

  const key = useMemo(() => getQueryKey(admin_id), [admin_id]);

  const query = useQuery({
    queryKey: key,
    queryFn: ({ queryKey }) => fetchBillingEntities(token, queryKey),
    staleTime: 0,
  });

  return {
    billingEntries: useMemo(() => query?.data?.data || { ids: [], data: {} }, [query.data]),
    isLoading: query.isLoading,
  };
}
