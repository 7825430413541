const dashboardTabReducer = (state = [], action) => {
     switch (action.type) {
         case 'ALL_DASHBOARD_TAB_TYPES':
            return action.data;
         default:
             return state;
     }
 }
 export default dashboardTabReducer;
 
