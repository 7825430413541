import { useMutation } from '@tanstack/react-query';

import { useUserData } from 'user/hooks/useUserData';
import { useMemo, useCallback } from 'react';
import { useModalStore } from 'common/hooks/useModals';
import { toast } from 'react-toastify';
import ApiError from 'errors/ApiError';
import { invalidateAccountDetails } from './useAccountById';

const updateAccount = async ({
  token, company_id, billing_account_code, session_duration, newAccountName,
}) => fetch(`${process.env.REACT_APP_BASEURL}/admin/updateAccount`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
  body: JSON.stringify({
    company_id,
    billing_account_code,
    session_duration,
    newAccountName,
  }),
}).then(async (res) => {
  const data = await res.json();

  if (!res.ok) {
    throw new ApiError(res, data);
  }

  return {
    response: res,
    responseData: data,
  };
});

const useUpdateAccount = () => {
  const {
    setUpdateAccountState,
  } = useModalStore();

  const {
    userData,
  } = useUserData();

  const {
    token,
  } = userData;

  const mutation = useMutation({
    mutationFn: updateAccount,
    // eslint-disable-next-line no-unused-vars
    onError: (error, variables, context) => {
      if (error instanceof ApiError) {
        const { responseData } = error;

        toast.error(responseData.message);
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSuccess: (data, variables, context) => {
      toast.success(data.responseData.message);
      // toast a success message, and close the modal
      invalidateAccountDetails({ company_id: variables.company_id });
      setUpdateAccountState(false);
    },
  });

  const doMutation = useCallback((params = {}, options = {}) => mutation.mutate({
    token, ...params,
  }, options), [mutation, token]);

  return useMemo(() => ({
    mutation: doMutation,
  }), [doMutation]);
};

export { useUpdateAccount };
