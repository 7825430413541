/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useUserData } from 'user/hooks/useUserData';

const getAircraftMakeModel = async (token, queryKey) => {
  // Extract the tailnumber from the queryKey
  const [,, aircraftMake] = queryKey;

  const response = await fetch(`${process.env.REACT_APP_BASEURL}/listAircraftMakeModels?make=${aircraftMake}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const result = await response.json();
  return { response, data: result.data };
};

export const getQueryKey = (aircraftMake) => ['aircraftGlobals', 'aircraftMakeModel', aircraftMake];

export function useAircraftMakeModel(aircraftMake) {
  const { userData } = useUserData();
  const { token } = userData;
  const key = useMemo(() => getQueryKey(aircraftMake), [aircraftMake]);

  const query = useQuery({
    queryKey: key,
    queryFn: ({ queryKey }) => getAircraftMakeModel(token, queryKey),
    enabled: !!aircraftMake,
  });

  return query?.data?.data || [];
}
