const tabReducer = (state = [], action) => {
     switch (action.type) {
         case 'ALL_PLANE_TAB_TYPES':
            return action.data;
         default:
             return state;
     }
 }
 export default tabReducer;
 
