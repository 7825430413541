import { Dialog } from '@mui/material';
import { useCallback } from 'react';

import { useModalStore } from 'common/hooks/useModals';
import ModalShell from 'aircraft/components/AddAircraftModal/ModalShell';

function View({ isOpen, handleClose }) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
    >
      <ModalShell />
    </Dialog>
  );
}

function Model() {
  const {
    addAircraft,
    setAddAircraftState,
  } = useModalStore();

  const { isOpen } = addAircraft;

  const handleClose = useCallback(() => {
    setAddAircraftState(false);
  }, [setAddAircraftState]);

  const hookProps = {
    isOpen,
    handleClose,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
