import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

function NumberInput({ onChange, value, ...props }) {
  const handleChange = (newValue) => {
    if (onChange && typeof onChange === 'function') {
      onChange(newValue);
    }
  };
  return (
    <TextField
      {...props}
      fullWidth
      name="session_duration"
      size="small"
      type="number"
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      InputProps={{
        inputProps: { min: 1 },
        endAdornment: (
          <InputAdornment
            position="end"
            style={{
              display: 'flex', flexDirection: 'column', height: 'auto', maxHeight: 'fit-content',
            }}
          >
            <IconButton
              aria-label="decrease value"
              onClick={() => handleChange((parseInt(value, 10) || 0) - 1)}
              size="small"
            >
              <MdKeyboardArrowDown />
            </IconButton>
            <IconButton
              aria-label="increase value"
              onClick={() => handleChange((parseInt(value, 10) || 0) + 1)}
              size="small"
            >
              <MdKeyboardArrowUp />
            </IconButton>
          </InputAdornment>
        ),
      }} // Optional: Set minimum value
    />
  );
}

export default NumberInput;
