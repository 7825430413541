/* eslint-disable camelcase */
import {
  Box, DialogActions, Button, Stack, DialogContent,
} from '@mui/material';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';

import { useModalStore } from 'common/hooks/useModals';
import { serializeFormData } from 'common/util/serializeFormData';
import { MdOutlineClose } from 'react-icons/md';
import { CanAddToAccountProvider } from 'aircraft/providers/CanAddToAccount';
import { useTransferDeactivatedAircraft } from 'aircraft/hooks/useTransferDeactivatedAircraft';
import TransferDeactivatedAircraft from './TransferDeactivatedAircraft';

const TransferDeactivatedAircraftHeader = styled.div`
    font-weight: bold;
    font-size: 24px;
    padding: 12px;
  `;

const ModalDivider = styled(Divider)`
    backgroundColor: '#000';
`;

const ActionButton = styled(Button)`
    font-size: 10px;
`;

const ModalHeaderActionContainer = styled(Stack)`
  justify-content: space-between;
`;

const CloseButton = styled(Button)`
  font-size: 20px;
  color: black;
`;

const SubmitButton = styled(ActionButton)`
  width: 120px;
`;

function View({
  reg_n_number,
  originAccountName,
  onSubmit,
  handleClose,
}) {
  return (
    <form onSubmit={onSubmit}>
      <ModalHeaderActionContainer spacing={2} direction="row">
        <TransferDeactivatedAircraftHeader>
          Aircraft Transfer
        </TransferDeactivatedAircraftHeader>
        <CloseButton variant="text" onClick={handleClose}>
          <MdOutlineClose />
        </CloseButton>
      </ModalHeaderActionContainer>
      <ModalDivider component="div" />
      <DialogContent>
        <TransferDeactivatedAircraft
          reg_n_number={reg_n_number}
          originAccountName={originAccountName}
        />
      </DialogContent>
      <ModalDivider component="div" />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box />
        <Stack spacing={2} direction="row">
          <SubmitButton variant="contained" color="primary" type="submit">
            Transfer
          </SubmitButton>
        </Stack>
      </DialogActions>
    </form>
  );
}

function Model() {
  const { mutation: doTransferDeactivatedAircraft } = useTransferDeactivatedAircraft();

  const {
    transferDeactivatedAircraft,
    setTransferDeactivatedAircraftState,
  } = useModalStore();

  const {
    reg_n_number,
    originAccountName,
    originCompanyId,
  } = transferDeactivatedAircraft;

  const onSubmit = async (e) => {
    e.preventDefault();

    const {
      dest_company_id,
    } = serializeFormData(e);

    doTransferDeactivatedAircraft({
      reg_n_number,
      origin_company_id: originCompanyId,
      dest_company_id,
      new_user_id: 0,
    });
  };

  const handleClose = () => {
    setTransferDeactivatedAircraftState(false);
  };

  const hookProps = {
    reg_n_number,
    originAccountName,
    onSubmit,
    handleClose,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

function ProvidedModel() {
  return (
    <CanAddToAccountProvider>
      <Model />
    </CanAddToAccountProvider>
  );
}

export default ProvidedModel;
export { View };
