const notificationReducer = (state = [], action) => {
     switch (action.type) {
        case 'GET_NOTIFICATIONS':  
            return  action.data;
         default:
             return state;
            
     }
 }
 export default notificationReducer;
 
