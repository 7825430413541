const accountTabReducer = (state = [], action) => {
     switch (action.type) {
         case 'ACCOUNT_TAB_TYPES':
            return action.data;
         default:
             return state;
     }
 }
 export default accountTabReducer;
 
