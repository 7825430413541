const profileReducer = (state = [], action) => {
     switch (action.type) {
         case 'ADD_PROFILE':
             return action.data;
        case 'GET_PROFILE':
          
            return  action.data;
         default:
             return state;
            
     }
 }
 export default profileReducer;
 
