const accountReducer = (state = [], action) => {
     switch (action.type) {
         case 'UPDATE_ACCOUNT':
             return action.data;
         default:
             return state;
     }
 }
 export default accountReducer;
 
