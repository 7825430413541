import styled from '@emotion/styled';
import {
  TextField, FormControl,
} from '@mui/material';
import Grid from '@mui/material/Grid';

const GridContainer = styled(Grid)`
  margin-bottom: 16px;
`;

const FieldLabel = styled(Grid)`
  font-weight: bold;
  font-size: 14px;
`;

function View() {
  return (
    <>
      <GridContainer item xs={12} md={6}>
        <FormControl fullWidth>
          <Grid container alignItems="center" spacing={2}>
            <FieldLabel item xs={12} sm={2}>Account name:</FieldLabel>
            <Grid item xs={12} sm={6}>
              <TextField
                name="account_name"
                fullWidth
                size="small"
                type="text"
                required
              />
            </Grid>
          </Grid>
        </FormControl>
      </GridContainer>
      <GridContainer item xs={12} md={6}>
        <FormControl fullWidth>
          <Grid container alignItems="center" spacing={2}>
            <FieldLabel item xs={12} sm={2}>Purpose:</FieldLabel>
            <Grid item xs={12} sm={6}>
              <TextField
                name="purpose"
                fullWidth
                multiline
                size="small"
                type="text"
                rows={4}
                required
                inputProps={{
                  placeholder: 'Explain the purpose of creating an unbilled account (eg. testing, pre-sales, demo, integration partner, etc)',
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
      </GridContainer>
    </>
  );
}

function Model() {
  const hookProps = {

  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
