/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import axiosApis from 'common/apis/axiosApis';
import { queryClient } from 'common/const/queryClient';

export const useAccountAircraftParamsStore = create((set) => ({
  min: 0,
  max: 25,
  searchText: '',
  activePage: 1,
  setMin: (min) => set({ min }),
  setMax: (max) => set({ max }),
  setSearchText: (searchText) => set({ searchText }),
  setActivePage: (activePage) => set({ activePage }),
  reset: () => set({
    min: 0,
    max: 25,
    searchText: '',
    activePage: 1,
  }),
}));

async function getAccountAircraftData({ queryKey }) {
  const [_, company_id, { searchText, min, max }] = queryKey;
  const userDetails = JSON.parse(localStorage.getItem('adminDetails'));

  return axiosApis.get(`/admin/getAllAircrafts/${company_id}?min=${min}&max=${max}&search_text=${searchText}`, {
    headers: {
      Authorization: userDetails.token,
    },
  }).then(async (response) => {
    await response;
    return response;
  });
}

export const getQueryKey = ({
  company_id,
  searchText,
  min,
  max,
  activePage,
}) => ['useAccountAircraft', company_id, {
  searchText,
  min,
  max,
  activePage,
}];

export const invalidateAccountAircraft = (params) => {
  if (params) {
    queryClient.invalidateQueries(getQueryKey(params));
  } else {
    queryClient.invalidateQueries(['useAccountAircraft']);
  }
};

export function useAccountAircraft({ company_id }) {
  const queryParams = useAccountAircraftParamsStore(useShallow(
    (state) => ({
      min: state.min,
      max: state.max,
      searchText: state.searchText,
      activePage: state.activePage,
    }),
  ));

  const key = useMemo(() => getQueryKey({
    company_id,
    ...queryParams,
  }), [company_id, queryParams]);

  const {
    isPending, isError, error, data, isFetching, isPlaceholderData,
  } = useQuery({
    queryKey: key,
    queryFn: getAccountAircraftData,
    staleTime: 1000 * 15,
    placeholderData: keepPreviousData,
    enabled: !!company_id,
  });

  return useMemo(() => ({
    isPending,
    isError,
    error,
    isFetching,
    isPlaceholderData,
    aircraft: data?.data?.planesData || [],
    aircraftCount: data?.data?.planesCount || 0,
  }), [data, error, isError, isFetching, isPending, isPlaceholderData]);
}
