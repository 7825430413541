class Configuration {
  BASEURL=process.env.REACT_APP_BASEURL;
  defaultAuthorization=process.env.REACT_APP_default_Authorization;
  envCOLLABRATION_IMG_PATH=process.env.REACT_APP_envCOLLABRATION_IMG_PATH;
  imageBaseURL=process.env.REACT_APP_imageBaseURL;
  adminBaseURL=process.env.REACT_ADMIN_URL;
  cipherKey=process.env.REACT_APP_CIPHER;
  appBaseURL=process.env.REACT_APP_BASE_URL;
  SIMULATE_URL=process.env.REACT_APP_SIMULATE_URL;
  REACT_APP_ENV=process.env.REACT_APP_ENV;
}
export default Configuration;
