import styled from '@emotion/styled';

import AvailableRecurlyAccountsTable from 'account/components/CreateAccountModal/AvailableRecurlyAccountsTable/Table';

const FoundAccountsHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10xp;
  padding-bottom: 12px;
`;

const AccountTypeHeader = styled.div`
  font-size: 16px;
  padding-bottom: 24px;
  padding-top: 24px;
`;

function View() {
  return (
    <>
      <FoundAccountsHeader>
        Unmatched accounts found in Recurly:
      </FoundAccountsHeader>
      <AvailableRecurlyAccountsTable />
      <AccountTypeHeader>
        After picking an unmatched account from Recurly above, select an account type.
      </AccountTypeHeader>
    </>
  );
}

function Model() {
  const hookProps = {

  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
