import { combineReducers } from 'redux'
import account from './accountReducer'
import planes from './planeReducers'
import logbooks from './logBookReducer'
import categories from './categoryReducer'
import profiles from './profileReducer'
import users from './userReducer'
import document from './documentReducer'
import defaultPlane from './defaultReducer'
import getNotifications from './notificationReducer'
import addDocument from './documentsReducers' 
import editPlane from './defaultPlaneReducers'
import tabReducer from './pageType'
import dashboardTabReducer from './dashBoradType'
import accountTabReducer from './accountType'
import subscriptionTabReducer from './subscriptionType'
import totalDocuments from './totalDocuments'

export default combineReducers({
  account,
  planes,
  logbooks,
  categories,
  users,
  profiles,
  document,
  defaultPlane,
  addDocument,
  editPlane,
  tabReducer,
  dashboardTabReducer,
  accountTabReducer,
  subscriptionTabReducer,
  getNotifications,
  totalDocuments,
})
