import { useModalStore } from 'common/hooks/useModals';
import {
  Dialog,
} from '@mui/material';
import { useCallback } from 'react';

import CreateAccount from 'account/components/CreateAccountModal/CreateAccount';

function View({
  isOpen, handleClose,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
    >
      <CreateAccount
        handleClose={handleClose}
      />
    </Dialog>
  );
}

function Model() {
  const {
    createAccount,
    setCreateAccountState,
  } = useModalStore();

  const { isOpen } = createAccount;

  const handleClose = useCallback(() => {
    setCreateAccountState(false);
  }, [setCreateAccountState]);

  const hookProps = {
    isOpen,
    handleClose,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
