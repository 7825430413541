/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useUserData } from 'user/hooks/useUserData';
import { LIST_AIRCRAFT_MAKES_GROUP_TYPES } from 'bluetail-globals/constants';

const getAircraftMake = async (token, queryKey) => {
  const [,, type] = queryKey;
  const response = await fetch(`${process.env.REACT_APP_BASEURL}/listAircraftMakes/${type}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const result = await response.json();
  return { response, data: result.data };
};

export const getQueryKey = (type) => ['aircraftGlobals', 'aircraftMake', type];

export function useAircraftMake() {
  const { userData } = useUserData();
  const { token } = userData;
  const popularKey = useMemo(() => getQueryKey(LIST_AIRCRAFT_MAKES_GROUP_TYPES.POPULAR), []);
  const rareKey = useMemo(() => getQueryKey(LIST_AIRCRAFT_MAKES_GROUP_TYPES.RARE), []);

  const popularQuery = useQuery({
    queryKey: popularKey,
    queryFn: ({ queryKey }) => getAircraftMake(token, queryKey),
  });

  const rareQuery = useQuery({
    queryKey: rareKey,
    queryFn: ({ queryKey }) => getAircraftMake(token, queryKey),
  });

  const fullList = useMemo(() => {
    const baseList = [];

    if (popularQuery?.data?.data) {
      baseList.push(...popularQuery.data.data);
    }

    if (rareQuery?.data?.data) {
      baseList.push(...rareQuery.data.data);
    }

    baseList.sort((a, b) => a.make.localeCompare(b.make));

    return baseList;
  }, [popularQuery?.data, rareQuery?.data]);

  return {
    aircraftMakes: fullList,
    isLoading: popularQuery.isLoading || rareQuery.isLoading,
    isError: popularQuery.isError || rareQuery.isError,
  };
}
