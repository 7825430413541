import { useModalStore } from 'common/hooks/useModals';
import {
  Box, Button, DialogActions, DialogContent, Divider, Stack,
} from '@mui/material';
import { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import styled from '@emotion/styled';
import { ACCOUNT_STYLE } from 'bluetail-globals/constants';

import CreateBilledAccount from 'account/components/CreateAccountModal/CreateBilledAccount';
import CreateUnbilledAccount from 'account/components/CreateAccountModal/CreateUnbilledAccount';
import { serializeFormData } from 'common/util/serializeFormData';
import { useAvailableBillingEntities } from 'account/hooks/useListAvailableBillingEntities';
import { useAddAccount } from 'account/hooks/useAddAccount';
import TraditionalAccountTypeButton from 'account/components/CreateAccountModal/AccountTypePickerButtons/Traditional';
import PartsAccountTypeButton from 'account/components/CreateAccountModal/AccountTypePickerButtons/Parts';
import { toast } from 'react-toastify';

const ModalDivider = styled(Divider)`
  backgroundColor: '#000';
`;

const AccountTypeLabel = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
`;

const AccountTypeContainer = styled(Box)`
  display: flex;
  justify-content: space-between; 
  width: 65%;
  margin-bottom: 10px;
  align-items: center;
`;

const ActionButton = styled(Button)`
  font-size: 10px;
`;

const CloseButton = styled(Button)`
  font-size: 20px;
  color: black;
`;

const ModalHeaderActionContainer = styled(Stack)`
  justify-content: space-between;
`;

const ModalFooterActionContainer = styled(DialogActions)`
  justify-content: space-between;
`;

const CreateAccountHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding: 12px;
`;

function View({
  isUnbilledMode, handleClose, onSubmit, handleBilled, handleUnbilled, handleSelectedAccountStyle, selectedAccountType,
}) {
  return (
    <form onSubmit={onSubmit}>
      <ModalHeaderActionContainer spacing={2} direction="row">
        <CreateAccountHeaderTitle>
          Create New Bluetail Account
        </CreateAccountHeaderTitle>
        <CloseButton variant="text" onClick={handleClose}>
          <MdOutlineClose />
        </CloseButton>
      </ModalHeaderActionContainer>
      <ModalDivider component="div" />
      <DialogContent>
        {!isUnbilledMode && (
        <CreateBilledAccount />
        )}
        {isUnbilledMode && (
        <CreateUnbilledAccount />
        )}
        <AccountTypeContainer>
          <AccountTypeLabel>
            Account Type:
          </AccountTypeLabel>
          <TraditionalAccountTypeButton
            isSelected={selectedAccountType === ACCOUNT_STYLE.TRADITIONAL}
            onClick={handleSelectedAccountStyle}
          />
          <AccountTypeLabel>
            Or
          </AccountTypeLabel>
          <PartsAccountTypeButton
            isSelected={selectedAccountType === ACCOUNT_STYLE.PARTS}
            onClick={handleSelectedAccountStyle}
          />
        </AccountTypeContainer>
      </DialogContent>
      <ModalDivider component="div" />
      <ModalFooterActionContainer>
        {!isUnbilledMode && (
        <ActionButton variant="text" onClick={handleUnbilled}>
          or create an unbilled account (for testing,demos etc.,)
        </ActionButton>
        )}
        {isUnbilledMode && (
        <ActionButton variant="text" onClick={handleBilled}>
          {'< Back to Billed Account'}
        </ActionButton>
        )}
        <ActionButton variant="contained" color="primary" type="submit">
          Create Account
        </ActionButton>
      </ModalFooterActionContainer>
    </form>
  );
}

function Model({ handleClose }) {
  const { mutation: addAccountMutation } = useAddAccount();
  const { billingEntries } = useAvailableBillingEntities();
  const [selectedAccountType, setSelectedAccountStyle] = useState(ACCOUNT_STYLE.TRADITIONAL);

  const {
    data,
  } = billingEntries;

  const handleSelectedAccountStyle = (styleType) => {
    setSelectedAccountStyle(styleType);
  };

  const {
    createAccount,
    setCreateAccountState,
  } = useModalStore();

  const {
    isUnbilledMode = false,
  } = createAccount;

  const handleUnbilled = () => {
    setCreateAccountState(true, {
      isUnbilledMode: true,
    });
  };

  const handleBilled = () => {
    setCreateAccountState(true, {
      isUnbilledMode: false,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { billing_account_code, ...formData } = serializeFormData(e);
    const accountData = data[billing_account_code];

    if (!isUnbilledMode && !billing_account_code) {
      toast.warn('Please select a Recurly account from the list');

      return;
    }

    if (!billing_account_code && formData?.purpose?.length < 20) {
      toast.warn('Purpose must be at least 20 characters');

      return;
    }

    addAccountMutation({
      billing_account_code,
      account_name: billing_account_code ? accountData.account_name : formData.account_name,
      isBilled: !isUnbilledMode,
      purpose: billing_account_code ? undefined : formData.purpose,
      style: selectedAccountType,
    });
  };

  const hookProps = {
    isUnbilledMode,
    handleClose,
    handleBilled,
    handleUnbilled,
    onSubmit,
    handleSelectedAccountStyle,
    selectedAccountType,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
