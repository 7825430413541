import { useMutation } from '@tanstack/react-query';
import { useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useUserData } from 'user/hooks/useUserData';
import { useModalStore } from 'common/hooks/useModals';
import ApiError from 'errors/ApiError';
import { queryClient } from 'common/const/queryClient';
import { getQueryKey as getListAvailableBillingEntitiesKey } from './useListAvailableBillingEntities';
import { invalidateAllAccounts } from './useAllAccounts';

const addAccount = async ({
  token, billing_account_code, account_name, isBilled, purpose, style,
}) => fetch(`${process.env.REACT_APP_BASEURL}/admin/addAccount`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
  body: JSON.stringify({
    billing_account_code,
    account_name,
    billed: isBilled,
    purpose,
    style,
  }),
}).then(async (res) => {
  const data = await res.json();

  if (!res.ok) {
    throw new ApiError(res, data);
  }

  return {
    response: res,
    responseData: data,
  };
});

const useAddAccount = () => {
  const {
    setCreateAccountState,
  } = useModalStore();

  const {
    userData,
  } = useUserData();

  const {
    token,
    admin_id,
  } = userData;

  const mutation = useMutation({
    mutationFn: addAccount,
    // eslint-disable-next-line no-unused-vars
    onError: (error, variables, context) => {
      if (error instanceof ApiError) {
        const { responseData } = error;

        toast.error(responseData.message);
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSuccess: (data, variables, context) => {
      const listAvailableBillingEntitiesKey = getListAvailableBillingEntitiesKey(admin_id);
      queryClient.invalidateQueries(listAvailableBillingEntitiesKey);
      invalidateAllAccounts();

      toast.success(data.responseData.message);
      // toast a success message, and close the modal
      setCreateAccountState(false);
    },
  });

  const doMutation = useCallback((params = {}, options = {}) => mutation.mutate({
    token, ...params,
  }, options), [mutation, token]);

  return useMemo(() => ({
    mutation: doMutation,
  }), [doMutation]);
};

export { useAddAccount };
