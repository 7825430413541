import {
  Button, Stack,
} from '@mui/material';
import styled from '@emotion/styled';
import { ACCOUNT_STYLE } from 'bluetail-globals/constants';

const AccountTypePickeButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$isSelected',
})`
  width: 160px;
  height: 130px;
  border: 2px solid #000 !important;
  ${({ $isSelected }) => $isSelected && 'background-color: #6fa9db !important;'}
  color: #000;
  border-radius: 4px;
`;

const AccountTypePickerButtonHeader = styled.div`
  font-size: 14px;
  text-transform: none;
  font-weight: bold;
`;

const AccountTypePickerButtonPoint = styled.div`
  font-size: 12px;
  text-transform: none;
  line-height: 1.0;
`;

function View({
  isSelected,
  handleClick,
}) {
  return (
    <AccountTypePickeButton
      variant="outlined"
      $isSelected={isSelected}
      onClick={handleClick}
    >
      <Stack direction="column" spacing={1}>
        <AccountTypePickerButtonHeader>
          Parts
        </AccountTypePickerButtonHeader>
        <AccountTypePickerButtonPoint>
          - Asset-oriented.
        </AccountTypePickerButtonPoint>
        <AccountTypePickerButtonPoint>
          - Asset/Group terminology.
        </AccountTypePickerButtonPoint>
        <AccountTypePickerButtonPoint>
          - Customer can setup infinite assets.
        </AccountTypePickerButtonPoint>
      </Stack>
    </AccountTypePickeButton>
  );
}

function Model({ onClick, isSelected }) {
  const handleClick = () => {
    onClick(ACCOUNT_STYLE.PARTS);
  };

  const hookProps = {
    isSelected,
    handleClick,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
