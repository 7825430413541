import React, {Suspense, lazy} from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom'
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client';

import * as serviceWorker from './serviceWorker'
import StyleRoot from 'common/theme/root'
import { queryClient } from 'common/const/queryClient'
import rootReducer from './reducers'
import Config from './config'
import LoadingImage from 'images/loading.gif';
import ModalManager from 'common/components/ModalManager';

import 'bootstrap3/dist/css/bootstrap.min.css'
import './common/css/style.css'
import './index.css'

const Login=lazy(() => import('./components/Login/Login'))
const Logout=lazy(() => import('./components/Logout/Logout'))
const Home=lazy(() => import('./components/Home/Home'))
const ChangePassword=lazy(() => import('./components/ChangePassword/ChangePassword'))

const fakeAuth={
  isAuthenticated: () => (localStorage.getItem('adminDetails')? true:false)
}

function saveToLocalStorage(store) {
  try {
    const serializedStore=JSON.stringify(store);
    window.localStorage.setItem('store', serializedStore);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedStore=window.localStorage.getItem('store');
    if (serializedStore===null) return undefined;
    return JSON.parse(serializedStore);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const persistedState=loadFromLocalStorage();

const store=createStore(rootReducer, persistedState);

store.subscribe(() => saveToLocalStorage(store.getState()));

const config=new Config()

function PrivateRoute({component: Component, ...rest}) {
  return (
    <Route
      {...rest}
      render={props =>
        fakeAuth.isAuthenticated()? (
          <Component {...props} />
        ):(
          <Redirect
            to={{
              pathname: '/',
              state: {from: props.location}
            }}
          />
        )
      }
    />
  )
}

const routing=(
  <Router>
    <Suspense fallback={(
      <div>
        <div className="parent">
          <img
            src={LoadingImage}
            alt="Loading"
          />    
        </div> 
      </div>
    )}>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/logout' component={Logout} />
        <Route path='/changepassword/:token' component={ChangePassword} />
        <PrivateRoute path='/home' component={Home} />
      </Switch>
    </Suspense>
  </Router>
);

const reduxStore=(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <StyleRoot />
      <ModalManager />
      {routing}
    </QueryClientProvider>
  </Provider>
)

// Old way:
// ReactDOM.render(reduxStore, document.getElementById('root'))
// New way:
const root = createRoot(document.getElementById('root'));
root.render(reduxStore);


serviceWorker.register()
