import { useModalStore } from 'common/hooks/useModals';
import CreateAccountModal from 'account/components/CreateAccountModal';
import UpdateAccount from 'account/components/UpdateAccountModal';
import AddAircraftModal from 'aircraft/components/AddAircraftModal';
import TransferDeactivatedAircraft from 'aircraft/components/TransferDeactivatedAircraftModal';

function View({
  showCreateAccount,
  showUpdateAccount,
  showAddAircraft,
  showTransferDeactivatedAircraft,
}) {
  return (
    <>
      {showCreateAccount && (
        <CreateAccountModal />
      )}
      {showAddAircraft && (
        <AddAircraftModal />
      )}
      {showUpdateAccount && (
        <UpdateAccount />
      )}
      {showTransferDeactivatedAircraft && (
        <TransferDeactivatedAircraft />
      )}
    </>
  );
}

function Model() {
  const {
    createAccount,
    updateAccount,
    addAircraft,
    transferDeactivatedAircraft,
  } = useModalStore();

  const hookProps = {
    showCreateAccount: createAccount.isOpen,
    showUpdateAccount: updateAccount.isOpen,
    showAddAircraft: addAircraft.isOpen,
    showTransferDeactivatedAircraft: transferDeactivatedAircraft.isOpen,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
