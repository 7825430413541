const documentReducer = (state = [], action) => {
     switch (action.type) {
         case 'ADD_DOCUMENTS':
             return action;
        case 'GET_DOCUMENTS':
            return action;
         default:
             return state;
     }
 }
 export default documentReducer;
 
