/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import axiosApis from 'common/apis/axiosApis';
import { queryClient } from 'common/const/queryClient';

export const useAllAccountsParamStore = create((set) => ({
  min: 0,
  max: 100,
  searchText: '',
  activePage: 1,
  setMin: (min) => set({ min }),
  setMax: (max) => set({ max }),
  setSearchText: (searchText) => set({ searchText }),
  setActivePage: (activePage) => set({ activePage }),
  reset: () => set({
    min: 0,
    max: 100,
    searchText: '',
    activePage: 1,
  }),
}));

async function getAllAccountsData({ queryKey }) {
  const [_, { searchText, min, max }] = queryKey;
  const userDetails = JSON.parse(localStorage.getItem('adminDetails'));

  return axiosApis.get(`/admin/getAllAccountsData?min=${min}&max=${max}&search_text=${searchText}`, {
    headers: {
      Authorization: userDetails.token,
    },
  }).then(async (response) => {
    await response;
    return response;
  });
}

export const getQueryKey = ({
  searchText,
  min,
  max,
  activePage,
}) => ['getAllAccountsData', {
  searchText,
  min,
  max,
  activePage,
}];

export const invalidateAllAccounts = (params) => {
  if (params) {
    queryClient.invalidateQueries(getQueryKey(params));
  } else {
    queryClient.invalidateQueries(['getAllAccountsData']);
  }
};

export function useAllAccounts() {
  const queryParams = useAllAccountsParamStore(useShallow(
    (state) => ({
      min: state.min,
      max: state.max,
      searchText: state.searchText,
      activePage: state.activePage,
    }),
  ));

  const { reset } = useAllAccountsParamStore(useShallow((state) => ({
    reset: state.reset,
  })));

  const key = useMemo(() => getQueryKey(queryParams), [queryParams]);

  const {
    isPending, isError, error, data, isFetching, isPlaceholderData,
  } = useQuery({
    queryKey: key,
    queryFn: getAllAccountsData,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 15,
  });

  // When this unmounts, reset the params store
  useEffect(() => () => {
    reset();
  }, [reset]);

  return useMemo(() => ({
    isPending,
    isError,
    error,
    isFetching,
    isPlaceholderData,
    allAccounts: data?.data?.manageAccountsAndUsers || [],
    totalAccountCount: data?.data?.totalAccountCount || 0,
  }), [data, error, isError, isFetching, isPending, isPlaceholderData]);
}
