/*
  Currently, this expects data to exist in localStorage from the Oldtail login flow.
  This flow is unobservable, and since Oldtail does mutate 'userDetails' under certain circumstances, this data will eventually
  de-sync from Oldtail.
*/
import { create } from 'zustand';

const useUserData = create((set) => ({
  userData: JSON.parse(localStorage.getItem('adminDetails')),
  setUserData: (nextUserData) => {
    set({ userData: nextUserData });
  },
  reset: () => set({ privileges: null }),
}));

export { useUserData };
