/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import axiosApis from 'common/apis/axiosApis';
import { queryClient } from 'common/const/queryClient';

async function getAccountById({ queryKey }) {
  const [_, { company_id }] = queryKey;
  const userDetails = JSON.parse(localStorage.getItem('adminDetails'));

  return axiosApis.get(`/admin/getAccountById/${company_id}`, {
    headers: {
      Authorization: userDetails.token,
    },
  }).then(async (response) => {
    await response;
    return response;
  });
}

export const getQueryKey = ({
  company_id,
}) => ['accountDataById', {
  company_id,
}];

export const invalidateAccountDetails = (params) => {
  if (params) {
    queryClient.invalidateQueries(getQueryKey(params));
  } else {
    queryClient.invalidateQueries(['accountDataById']);
  }
};

export function useAccountById({ company_id }) {
  const key = useMemo(() => getQueryKey({ company_id }), [company_id]);

  const query = useQuery({
    queryKey: key,
    queryFn: getAccountById,
    staleTime: 1000 * 60,
  });

  return useMemo(() => ({
    isLoading: query.isLoading,
    accountData: query?.data?.data?.company || {},
  }), [query.data, query.isLoading]);
}
